body {
	font-family: "Raleway", sans-serif;
	color: #1e1e22;
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	font-size: 14px;
}

@media (min-width: 768px) {
	body {
		font-size: 16px;
	}
}

@media (min-width: 1199px) {
	body {
		font-size: 18px;
	}
}

body.noscroll {
	overflow-y: hidden;
}

h1,
h2 {
	font-family: "Crete Round", serif;
	margin: 0;
}

h1 {
	font-size: 2em;
}

h2 {
	font-size: 1.5em;
}

p {
	margin: 0px;
	padding: 0px;
	font-size: 1em;
}

a {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

a:hover, a:focus {
	opacity: .9;
	outline: none;
}

img {
	max-width: 100%;
}

header {
	position: relative;
	height: 55px;
}

@media (max-width: 991px) {
	header .container {
		max-width: unset;
	}
}

@media (min-width: 992px) {
	header {
		height: 75px;
	}
}

header .logo {
	display: inline-block;
}

header .logo a img {
	height: 78px;
	width: auto;
}

@media (min-width: 992px) {
	header .logo a img {
		height: 120px;
	}
}

.pb-xs {
	padding-bottom: 15px;
}

.pv-xs {
	padding-top: 15px;
	padding-bottom: 15px;
}

.pt-sm {
	padding-top: 25px;
}

.pb-sm {
	padding-bottom: 25px;
}

.pv-sm {
	padding-top: 25px;
	padding-bottom: 25px;
}

.pt-md {
	padding-top: 30px;
}

.pb-md {
	padding-bottom: 30px;
}

.pt-xl {
	padding-top: 50px;
}

.pb-xl {
	padding-bottom: 50px;
}

.pv-xl {
	padding-top: 50px;
	padding-bottom: 50px;
}

.color--green {
	color: #b9d15e;
}

.color--green:before {
	background-color: #b9d15e;
}

.color--green:hover {
	color: #b9d15e;
}

.color--blue {
	color: #402eef;
}

.color--blue:before {
	background-color: #402eef;
}

.color--blue:hover {
	color: #402eef;
}

.bg--white {
	background-color: #f6f8fa;
}

.bg_gradient--yellow {
	background-image: -moz-linear-gradient(90deg, #ffcd32 0%, #fbdb77 100%);
	background-image: -webkit-linear-gradient(90deg, #ffcd32 0%, #fbdb77 100%);
	background-image: -ms-linear-gradient(90deg, #ffcd32 0%, #fbdb77 100%);
}

